import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import PulseLoader from "react-spinners/PulseLoader";
import CreditCardInput from "react-credit-card-input";
import isEmpty from "is-empty";
import usState from "../../utils/state.json";
import glp_offer from "../../utils/glp_offer.png";
import telmed from "../../utils/telmed.png";
import inquiry from "../../utils/inquiry.png";
const pricing = {
  "5mg": 150,
  "10mg": 260,
  "20mg": 480,
  "1mg": 85,
  "2M5mg": 100,
  "5M2mg": 135,
  "15mg": 185,
  "40mg": 225,
  "80mg": 350,
  t10mg: 115,
  t20mg: 200,
  t30mg: 280,
  t40mg: 340,
  t50mg: 420,
  t60mg: 450,
};

const pricing_sema = {
  "1gm": 1500,
  "5gm": 1450,
  "25gm": 1300,
  "100gm": 1200,
  "250gm": 1150,
};

const pricing_tirz = {
  "1gm": 1850,
  "5gm": 1800,
  "25gm": 1700,
  "100gm": 1650,
  "250gm": 1600,
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qty5mg: 0,
      qty10mg: 0,
      qty20mg: 0,
      qty20mg: 0,
      qty5mgTotal: 0,
      qty10mgTotal: 0,
      qty20mgTotal: 0,
      qty1mg: 0,
      qty2M5mg: 0,
      qty5M2mg: 0,
      qty15mg: 0,
      qty40mg: 0,
      qty80mg: 0,
      qtyt10mg: 0,
      qtyt20mg: 0,
      qtyt30mg: 0,
      qtyt40mg: 0,
      qtyt50mg: 0,
      qtyt60mg: 0,
      qty1mgTotal: 0,
      qty2M5mgTotal: 0,
      qty5M2mgTotal: 0,
      qty15mgTotal: 0,
      qty40mgTotal: 0,
      qty80mgTotal: 0,
      qtyt10mgTotal: 0,
      qtyt20mgTotal: 0,
      qtyt30mgTotal: 0,
      qtyt40mgTotal: 0,
      qtyt50mgTotal: 0,
      qtyt60mgTotal: 0,
      qtyTotalTotal: 0,
      errors: {},
      cardNumber: "",
      expiry: "",
      cvv: "",
      cardError: true,
      loading: false,
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      current: "first",
      qty_sema_api: 0,
      sema_api: undefined,
      qty_tirz_api: 0,
      tirz_api: undefined,
      qtysemaApiTotal: 0,
      qtytirzApiTotal: 0,
      payment_type: "credit_card",
      bank_name: undefined,
      account_number: undefined,
      routing_number: undefined,
    };
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  onChange = (e) => {
    let targetId = e.target.id;
    this.setState({ [targetId]: e.target.value }, () =>
      this.updatePricing(targetId)
    );
  };

  updatePricing = (targetId) => {
    let stateData = this.state;
    if (targetId === "qty5mg") {
      stateData = {
        ...stateData,
        qty5mgTotal:
          parseInt(this.state.qty5mg) * parseInt(pricing["5mg"] * 10),
      };
    } else if (targetId === "qty10mg") {
      stateData = {
        ...stateData,
        qty10mgTotal:
          this.state.qty10mg === ""
            ? 0
            : parseInt(this.state.qty10mg) * parseInt(pricing["10mg"] * 10),
      };
    } else if (targetId === "qty20mg") {
      stateData = {
        ...stateData,
        qty20mgTotal:
          this.state.qty20mg === ""
            ? 0
            : parseInt(this.state.qty20mg) * parseInt(pricing["20mg"] * 10),
      };
    } else if (targetId === "qty1mg") {
      stateData = {
        ...stateData,
        qty1mgTotal:
          this.state.qty1mg === ""
            ? 0
            : parseInt(this.state.qty1mg) * parseInt(pricing["1mg"] * 10),
      };
    } else if (targetId === "qty2M5mg") {
      stateData = {
        ...stateData,
        qty2M5mgTotal:
          this.state.qty2M5mg === ""
            ? 0
            : parseInt(this.state.qty2M5mg) * parseInt(pricing["2M5mg"] * 10),
      };
    } else if (targetId === "qty5M2mg") {
      stateData = {
        ...stateData,
        qty5M2mgTotal:
          this.state.qty5M2mg === ""
            ? 0
            : parseInt(this.state.qty5M2mg) * parseInt(pricing["5M2mg"] * 10),
      };
    } else if (targetId === "qty15mg") {
      stateData = {
        ...stateData,
        qty15mgTotal:
          this.state.qty15mg === ""
            ? 0
            : parseInt(this.state.qty15mg) * parseInt(pricing["15mg"] * 10),
      };
    } else if (targetId === "qty40mg") {
      stateData = {
        ...stateData,
        qty40mgTotal:
          this.state.qty40mg === ""
            ? 0
            : parseInt(this.state.qty40mg) * parseInt(pricing["40mg"] * 10),
      };
    } else if (targetId === "qty80mg") {
      stateData = {
        ...stateData,
        qty80mgTotal:
          this.state.qty80mg === ""
            ? 0
            : parseInt(this.state.qty80mg) * parseInt(pricing["80mg"] * 10),
      };
    } else if (targetId === "qtyt10mg") {
      stateData = {
        ...stateData,
        qtyt10mgTotal:
          this.state.qtyt10mg === ""
            ? 0
            : parseInt(this.state.qtyt10mg) * parseInt(pricing["t10mg"] * 10),
      };
    } else if (targetId === "qtyt20mg") {
      stateData = {
        ...stateData,
        qtyt20mgTotal:
          this.state.qtyt20mg === ""
            ? 0
            : parseInt(this.state.qtyt20mg) * parseInt(pricing["t20mg"] * 10),
      };
    } else if (targetId === "qtyt30mg") {
      stateData = {
        ...stateData,
        qtyt30mgTotal:
          this.state.qtyt30mg === ""
            ? 0
            : parseInt(this.state.qtyt30mg) * parseInt(pricing["t30mg"] * 10),
      };
    } else if (targetId === "qtyt40mg") {
      stateData = {
        ...stateData,
        qtyt40mgTotal:
          this.state.qtyt40mg === ""
            ? 0
            : parseInt(this.state.qtyt40mg) * parseInt(pricing["t40mg"] * 10),
      };
    } else if (targetId === "qtyt50mg") {
      stateData = {
        ...stateData,
        qtyt50mgTotal:
          this.state.qtyt50mg === ""
            ? 0
            : parseInt(this.state.qtyt50mg) * parseInt(pricing["t50mg"] * 10),
      };
    } else if (targetId === "qtyt60mg") {
      stateData = {
        ...stateData,
        qtyt60mgTotal:
          this.state.qtyt60mg === ""
            ? 0
            : parseInt(this.state.qtyt60mg) * parseInt(pricing["t60mg"] * 10),
      };
    } else if (targetId === "sema_api" || targetId === "qty_sema_api") {
      if (this.state.sema_api !== undefined && this.state.sema_api !== "") {
        stateData = {
          ...stateData,
          qtysemaApiTotal:
            parseInt(this.state.qty_sema_api) *
            parseInt(this.state.sema_api.split("gm")[0]) *
            parseInt(pricing_sema[this.state.sema_api]),
        };
      } else {
        stateData = {
          ...stateData,
          qtysemaApiTotal: 0,
        };
      }
    } else if (targetId === "tirz_api" || targetId === "qty_tirz_api") {
      if (this.state.tirz_api !== undefined && this.state.tirz_api !== "") {
        stateData = {
          ...stateData,
          qtytirzApiTotal:
            parseInt(this.state.qty_tirz_api) *
            parseInt(this.state.tirz_api.split("gm")[0]) *
            parseInt(pricing_tirz[this.state.tirz_api]),
        };
      } else {
        stateData = {
          ...stateData,
          qtytirzApiTotal: 0,
        };
      }
    }
    stateData = {
      ...stateData,
      qtyTotal:
        parseInt(stateData.qty20mgTotal) +
        parseInt(stateData.qty10mgTotal) +
        parseInt(stateData.qty5mgTotal) +
        parseInt(stateData.qty1mgTotal) +
        parseInt(stateData.qty2M5mgTotal) +
        parseInt(stateData.qty5M2mgTotal) +
        parseInt(stateData.qty15mgTotal) +
        parseInt(stateData.qty40mgTotal) +
        parseInt(stateData.qty80mgTotal) +
        parseInt(stateData.qtyt10mgTotal) +
        parseInt(stateData.qtyt20mgTotal) +
        parseInt(stateData.qtyt30mgTotal) +
        parseInt(stateData.qtyt40mgTotal) +
        parseInt(stateData.qtyt50mgTotal) +
        parseInt(stateData.qtyt60mgTotal) +
        parseInt(stateData.qtysemaApiTotal) +
        parseInt(stateData.qtytirzApiTotal),
    };
    this.setState(stateData);
  };

  register = (e) => {
    let { errors } = this.state;

    e.preventDefault();
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.street === "") {
      errors.street = "This field is required";
    } else {
      delete errors.street;
    }
    if (this.state.city === "") {
      errors.city = "This field is required";
    } else {
      delete errors.city;
    }
    if (this.state.state === "") {
      errors.state = "This field is required";
    } else {
      delete errors.state;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else {
      delete errors.zip;
    }
    if (this.state.termscondition === false) {
      errors.termscondition = "This field is required";
    } else {
      delete errors.termscondition;
    }
    if (this.state.payment_type === "ach_no") {
      if (
        this.state.account_number === "" ||
        this.state.account_number === undefined
      ) {
        errors.account_number = "This field is required";
      } else {
        delete errors.account_number;
      }
      if (
        this.state.routing_number === "" ||
        this.state.routing_number === undefined
      ) {
        errors.routing_number = "This field is required";
      } else {
        delete errors.routing_number;
      }
      if (this.state.bank_name === "" || this.state.bank_name === undefined) {
        errors.bank_name = "This field is required";
      } else {
        delete errors.bank_name;
      }
    } else {
      delete errors.bank_name;
      delete errors.routing_number;
      delete errors.account_number;
    }
    if (this.state.payment_type === "credit_card") {
      if (
        this.state.cardNumber === "" ||
        this.state.cardNumber === undefined ||
        this.state.cardError
      ) {
        errors.cardDetails = "Please fill correct card details";
        // this.setState({ cardError: true });
      } else {
        delete errors.cardDetails;
      }
    } else {
      delete errors.cardNumber;
    }
    if (isEmpty(errors)) {
      this.setState({ loading: true });
      const newUser = {
        email: this.state.email,
        price5mg: pricing["5mg"],
        price10mg: pricing["10mg"],
        price20mg: pricing["20mg"],
        price1mg: pricing["1mg"],
        price2M5mg: pricing["2M5mg"],
        price5M2mg: pricing["5M2mg"],
        price15mg: pricing["15mg"],
        price40mg: pricing["40mg"],
        price80mg: pricing["80mg"],
        pricet10mg: pricing["t10mg"],
        pricet20mg: pricing["t20mg"],
        pricet30mg: pricing["t30mg"],
        pricet40mg: pricing["t40mg"],
        pricet50mg: pricing["t50mg"],
        pricet60mg: pricing["t60mg"],
        qty5mg: this.state.qty5mg,
        qty10mg: this.state.qty10mg,
        qty20mg: this.state.qty20mg,
        qty10mgTotal: this.state.qty10mgTotal,
        qty20mgTotal: this.state.qty20mgTotal,
        qty5mgTotal: this.state.qty5mgTotal,
        //new added
        qty1mg: this.state.qty1mg,
        qty2M5mg: this.state.qty2M5mg,
        qty5M2mg: this.state.qty5M2mg,
        qty15mg: this.state.qty15mg,
        qty40mg: this.state.qty40mg,
        qty80mg: this.state.qty80mg,
        qtyt10mg: this.state.qtyt10mg,
        qtyt20mg: this.state.qtyt20mg,
        qtyt30mg: this.state.qtyt30mg,
        qtyt40mg: this.state.qtyt40mg,
        qtyt50mg: this.state.qtyt50mg,
        qtyt60mg: this.state.qtyt60mg,
        qty1mgTotal: this.state.qty1mgTotal,
        qty2M5mgTotal: this.state.qty2M5mgTotal,
        qty5M2mgTotal: this.state.qty5M2mgTotal,
        qty15mgTotal: this.state.qty15mgTotal,
        qty40mgTotal: this.state.qty40mgTotal,
        qty80mgTotal: this.state.qty80mgTotal,
        qtyt10mgTotal: this.state.qtyt10mgTotal,
        qtyt20mgTotal: this.state.qtyt20mgTotal,
        qtyt30mgTotal: this.state.qtyt30mgTotal,
        qtyt40mgTotal: this.state.qtyt40mgTotal,
        qtyt50mgTotal: this.state.qtyt50mgTotal,
        qtyt60mgTotal: this.state.qtyt60mgTotal,

        qtyTotal: this.state.qtyTotal,
        email: this.props.auth.user.email,
        pharmacy_name: this.props.auth.user.name,
        cardNumber: this.state.cardNumber,
        expiry: this.state.expiry,
        cvv: this.state.cvv,
        name: this.state.name,
        street: this.state.street,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        rep: this.props.auth.currentStore.code,
        qty_sema_api: this.state.qty_sema_api,
        qty_tirz_api: this.state.qty_tirz_api,
        qtysemaApiTotal: this.state.qtysemaApiTotal,
        qtytirzApiTotal: this.state.qtytirzApiTotal,
        sema_api: this.state.sema_api,
        sema_price: pricing_sema[this.state.sema_api],
        tirz_api: this.state.tirz_api,
        tirz_price: pricing_tirz[this.state.tirz_api],
        payment_type: this.state.payment_type,
        account_number: this.state.account_number,
        routing_number: this.state.routing_number,
        bank_name: this.state.bank_name,
      };

      axios
        .post("/api/order-add", newUser)

        .then((res) => {
          this.setState({
            loading: false,
            qty5mg: 0,
            qty10mg: 0,
            qty20mg: 0,
            qty_sema_api: 0,
            sema_api: undefined,
            qty_tirz_api: 0,
            tirz_api: undefined,
            loading: false,
            qty10mgTotal: 0,
            qty20mgTotal: 0,
            qty5mgTotal: 0,
            qtysemaApiTotal: 0,
            qtytirzApiTotal: 0,
            qty1mg: 0,
            qty2M5mg: 0,
            qty5M2mg: 0,
            qty15mg: 0,
            qty40mg: 0,
            qty80mg: 0,
            qtyt10mg: 0,
            qtyt20mg: 0,
            qtyt30mg: 0,
            qtyt40mg: 0,
            qtyt50mg: 0,
            qtyt60mg: 0,
            qty1mgTotal: 0,
            qty2M5mgTotal: 0,
            qty5M2mgTotal: 0,
            qty15mgTotal: 0,
            qty40mgTotal: 0,
            qty80mgTotal: 0,
            qtyt10mgTotal: 0,
            qtyt20mgTotal: 0,
            qtyt30mgTotal: 0,
            qtyt40mgTotal: 0,
            qtyt50mgTotal: 0,
            qtyt60mgTotal: 0,
            qtyTotalTotal: 0,
            qtyTotal: 0,
            current: "first",
            rep: this.props.auth.currentStore.code,
            cardNumber: "",
            expiry: "",
            cvv: "",
            name: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            account_number: "",
            routing_number: "",
            bank_name: "",
          });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      this.setState({ errors });
    }
  };

  changeStep = () => {
    if (this.state.qtyTotal < 1 || this.state.qtyTotal === null) {
      alert("Please choose atleast one qty");
    } else {
      this.setState({
        current: "last",
      });
    }
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  handleCardNumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value,
      cardError: false,
    });
  };

  handleCardExpiryChange = (e) => {
    this.setState({
      expiry: e.target.value,
      cardError: false,
    });
  };

  handleCardCVCChange = (e) => {
    this.setState({
      cvv: e.target.value,
      cardError: false,
    });
  };

  setError = () => {
    this.setState({
      cardError: true,
    });
  };

  onChangeRadio = (e) => {
    this.setState({
      [e.target.name]: e.target.id,
    });
  };

  render() {
    const { errors } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Navbar />
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <form noValidate onSubmit={this.register}>
              <div className="row px-2">
                {this.state.current === "first" ? (
                  <>
                    {1 && (
                      <>
                        <div className="col-4 mt-2">
                          <a href="tel:3524668977">
                            <img
                              width={400}
                              src={
                                "https://d3l9g5ay50k79j.cloudfront.net/glp/Order+inquries_.png"
                              }
                              className="mb-2 ml-2"
                            />
                          </a>
                        </div>
                        <div
                          className="col-4 mt-2"
                          style={{ textAlign: "center" }}
                        >
                          <a href="https://psgaonline.com/" target="_blank">
                            <img
                              width={400}
                              src={
                                "https://d3l9g5ay50k79j.cloudfront.net/glp/Need+print+Marketing.png"
                              }
                              className="mb-2 ml-2"
                            />
                          </a>
                        </div>
                        <div
                          className="col-4 mt-2"
                          style={{ textAlign: "right" }}
                        >
                          <a
                            href="https://login.rxconnexion.com/"
                            target="_blank"
                          >
                            <img
                              width={400}
                              src={
                                "https://d3l9g5ay50k79j.cloudfront.net/glp/Need+marketing_.png"
                              }
                              className="ml-2"
                            />
                          </a>
                        </div>
                      </>
                    )}
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h2 className="mt-2 text-primary float-left">
                            Request Form
                          </h2>
                        </div>

                        <div className="card-body">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Description</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Price</th>
                                <th scope="col">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">
                                  Semaglutide - 1mg/0.4ml (Vial) - 2.5ml - Box
                                  of 10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qty1mg}
                                    id="qty1mg"
                                    min={1}
                                    name="qty1mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["1mg"]}</td>
                                <td>${this.state.qty1mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Semaglutide - 2.5mg/ml (Vial) - 1ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qty2M5mg}
                                    id="qty2M5mg"
                                    min={1}
                                    name="qty2M5mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["2M5mg"]}</td>
                                <td>${this.state.qty2M5mgTotal}</td>
                              </tr>
                              {/* <tr>
                              <th scope="row">
                                Semaglutide - 2.5 mg/ml (Vial) - 2ml - Box of 10
                              </th>
                              <td>
                                <input
                                  type="number"
                                  value={this.state.qty5mg}
                                  id="qty5mg"
                                  min={1}
                                  name="qty5mg"
                                  onChange={this.onChange}
                                />
                              </td>
                              <td>${pricing["5mg"]}</td>
                              <td>${this.state.qty5mgTotal}</td>
                            </tr> */}
                              {/* <tr>
                              <th scope="row">
                                Semaglutide - 2.5 mg/ml (Vial) - 4ml - Box of 10
                              </th>
                              <td>
                                <input
                                  type="number"
                                  value={this.state.qty10mg}
                                  id="qty10mg"
                                  min={0}
                                  name="qty10mg"
                                  onChange={this.onChange}
                                />
                              </td>
                              <td>${pricing["10mg"]}</td>
                              <td>${this.state.qty10mgTotal}</td>
                            </tr> */}
                              <tr>
                                <th scope="row">
                                  Semaglutide - 5mg/2ml (Vial) - 2ml - Box of 10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qty5M2mg}
                                    id="qty5M2mg"
                                    min={0}
                                    name="qty5M2mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["5M2mg"]}</td>
                                <td>${this.state.qty5M2mgTotal}</td>
                              </tr>

                              <tr>
                                <th scope="row">
                                  Semaglutide - 7.5mg/3ml (Vial) - 3ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qty15mg}
                                    id="qty15mg"
                                    min={0}
                                    name="qty15mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["15mg"]}</td>
                                <td>${this.state.qty15mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Semaglutide - 10mg/4ml (Vial) - 4ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qty40mg}
                                    id="qty40mg"
                                    min={0}
                                    name="qty40mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["40mg"]}</td>
                                <td>${this.state.qty40mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Tirzepatide - 10mg/0.5ml (Vial) - 2ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qtyt10mg}
                                    id="qtyt10mg"
                                    min={0}
                                    name="qtyt10mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["t10mg"]}</td>
                                <td>${this.state.qtyt10mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Tirzepatide - 20mg/2ml (Vial) - 2ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qtyt20mg}
                                    id="qtyt20mg"
                                    min={0}
                                    name="qtyt20mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["t20mg"]}</td>
                                <td>${this.state.qtyt20mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Tirzepatide - 30mg/2ml (Vial) - 2ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qtyt30mg}
                                    id="qtyt30mg"
                                    min={0}
                                    name="qtyt30mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["t30mg"]}</td>
                                <td>${this.state.qtyt30mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Tirzepatide - 40mg/2ml (Vial) - 2ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qtyt40mg}
                                    id="qtyt40mg"
                                    min={0}
                                    name="qtyt40mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["t40mg"]}</td>
                                <td>${this.state.qtyt40mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Tirzepatide - 50mg/2ml (Vial) - 2ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qtyt50mg}
                                    id="qtyt50mg"
                                    min={0}
                                    name="qtyt50mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["t50mg"]}</td>
                                <td>${this.state.qtyt50mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Tirzepatide - 60mg/3ml (Vial) - 3ml - Box of
                                  10
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qtyt60mg}
                                    id="qtyt60mg"
                                    min={0}
                                    name="qtyt60mg"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>${pricing["t60mg"]}</td>
                                <td>${this.state.qtyt60mgTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  API Semaglutide
                                  <select
                                    onChange={this.onChange}
                                    id="sema_api"
                                    className="form-control"
                                  >
                                    <option value={""}>
                                      Please select size
                                    </option>
                                    <option
                                      value={"1gm"}
                                      selected={this.state.sema_api === "1gm"}
                                    >
                                      1gm ($1500/gm)
                                    </option>
                                    <option
                                      value={"5gm"}
                                      selected={this.state.sema_api === "5gm"}
                                    >
                                      5gm ($1450/gm)
                                    </option>
                                    <option
                                      value={"25gm"}
                                      selected={this.state.sema_api === "25gm"}
                                    >
                                      25gm ($1300/gm)
                                    </option>
                                    <option
                                      value={"100gm"}
                                      selected={this.state.sema_api === "100gm"}
                                    >
                                      100gm ($1200/gm)
                                    </option>
                                    <option
                                      value={"250gm"}
                                      selected={this.state.sema_api === "250gm"}
                                    >
                                      250gm ($1150/gm)
                                    </option>
                                  </select>
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qty_sema_api}
                                    id="qty_sema_api"
                                    min={0}
                                    name="qty_sema_api"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>
                                  {this.state.sema_api && (
                                    <>${pricing_sema[this.state.sema_api]}</>
                                  )}
                                </td>
                                <td>${this.state.qtysemaApiTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  API Tirzepatide
                                  <select
                                    onChange={this.onChange}
                                    id="tirz_api"
                                    className="form-control"
                                  >
                                    <option value={""}>
                                      Please select size
                                    </option>
                                    <option
                                      value={"1gm"}
                                      selected={this.state.tirz_api === "1gm"}
                                    >
                                      1gm ($1850/gm)
                                    </option>
                                    <option
                                      value={"5gm"}
                                      selected={this.state.tirz_api === "5gm"}
                                    >
                                      5gm ($1800/gm)
                                    </option>
                                    <option
                                      value={"25gm"}
                                      selected={this.state.tirz_api === "25gm"}
                                    >
                                      25gm ($1700/gm)
                                    </option>
                                    <option
                                      value={"100gm"}
                                      selected={this.state.tirz_api === "100gm"}
                                    >
                                      100gm ($1650/gm)
                                    </option>
                                    <option
                                      value={"250gm"}
                                      selected={this.state.tirz_api === "250gm"}
                                    >
                                      250gm ($1600/gm)
                                    </option>
                                  </select>
                                </th>
                                <td>
                                  <input
                                    type="number"
                                    value={this.state.qty_tirz_api}
                                    id="qty_tirz_api"
                                    min={0}
                                    name="qty_tirz_api"
                                    onChange={this.onChange}
                                  />
                                </td>
                                <td>
                                  {this.state.tirz_api && (
                                    <>${pricing_tirz[this.state.tirz_api]}</>
                                  )}
                                </td>
                                <td>${this.state.qtytirzApiTotal}</td>
                              </tr>
                              <tr>
                                <th scope="row" colSpan={2}></th>
                                <td>
                                  <b>Total</b>
                                </td>
                                <td>
                                  <b>${this.state.qtyTotal}</b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <p className="text-center pb-0 mt-2">
                            <button
                              type="button"
                              className="btn btn-large btn-primary mt-2 px-5 float-right"
                              onClick={this.changeStep}
                            >
                              Next
                            </button>
                          </p>
                          <br></br>
                          <br></br>
                          <br></br>
                          {this.props.auth.currentStore.code === "buy" &&
                          !this.props.auth.user.provider &&
                          !this.props.auth.user.superadmin ? (
                            <>
                              <p
                                style={{
                                  width: "80%",
                                  fontSize: "20px",
                                  float: "left",
                                }}
                              >
                                Dear Valued Client, <br></br>We want to remind
                                our pharmacy clients located in Alabama,
                                Colorado, Florida, Georgia, Illinois, Louisiana,
                                Maryland, Michigan, New Jersey, Ohio, Oklahoma,
                                Tennessee, Texas, Utah, Wisconsin and many more
                                about our <b>XPedicareRx</b> telemedicine
                                services. Through <b>XPedicareRx</b>{" "}
                                telemedicine, patients in these states can
                                seamlessly access your telemedicine services
                                that directly benefit your practice.
                              </p>
                              <p
                                style={{
                                  width: "20%",
                                  fontSize: "20px",
                                  float: "left",
                                  paddingTop: "25px",
                                }}
                              ></p>
                              <p
                                style={{
                                  width: "80%",
                                  fontSize: "20px",
                                }}
                              >
                                Discover the power of our marketing program at{" "}
                                <b>RxCreator</b>. Elevate your outreach and
                                engage more patients with our tailored
                                strategies. Register or inquire today to unlock
                                the full potential of our program by leveraging
                                digital marketing, print materials, and engaging
                                podcasts.
                              </p>
                              <p
                                style={{
                                  width: "100%",
                                  fontSize: "20px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-large btn-success mt-2 px-5"
                                  style={{ width: "200px" }}
                                  onClick={() =>
                                    window.open(
                                      `https://analytics.psgarx.com/register`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Enroll in our program
                                </button>
                              </p>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-12">
                    <br></br>
                    <div className="card">
                      <div className="card-header">
                        <h2 className="mt-2 text-primary">
                          Payment Information
                        </h2>
                      </div>
                      <div className="card-body">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payment_type"
                                id="credit_card"
                                checked={
                                  this.state.payment_type === "credit_card"
                                }
                                onChange={this.onChangeRadio}
                              />
                              <label
                                className="form-check-label"
                                for="credit_card"
                              >
                                Credit Card
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payment_type"
                                id="ach_no"
                                checked={this.state.payment_type === "ach_no"}
                                onChange={this.onChangeRadio}
                              />
                              <label className="form-check-label" for="ach_no">
                                ACH Details
                              </label>
                            </div>
                            {this.state.payment_type === "credit_card" ? (
                              <>
                                <CreditCardInput
                                  onError={() => this.setError()}
                                  cardNumberInputProps={{
                                    value: this.state.cardNumber,
                                    onChange: this.handleCardNumberChange,
                                    placeHolder: "Enter Card Number",
                                  }}
                                  cardExpiryInputProps={{
                                    value: this.state.expiry,
                                    onChange: this.handleCardExpiryChange,
                                  }}
                                  cardCVCInputProps={{
                                    value: this.state.cvv,
                                    onChange: this.handleCardCVCChange,
                                  }}
                                  fieldClassName="input"
                                />
                                <span
                                  className="text-danger"
                                  style={{ display: "block" }}
                                >
                                  {errors.cardDetails}
                                </span>
                              </>
                            ) : (
                              <div className="row">
                                <div className="col-md-4 col-12">
                                  <label htmlFor="bank_name">Bank Name</label>
                                  <input
                                    onChange={this.onChange}
                                    value={this.state.bank_name}
                                    id="bank_name"
                                    type="text"
                                    error={errors.bank_name}
                                    className={classnames("form-control", {
                                      invalid: errors.bank_name,
                                    })}
                                  />
                                  <span className="text-danger">
                                    {errors.bank_name}
                                  </span>
                                </div>
                                <div className="col-md-4 col-12">
                                  <label htmlFor="account_number">
                                    Acount Number
                                  </label>
                                  <input
                                    onChange={this.onChange}
                                    value={this.state.account_number}
                                    id="account_number"
                                    type="text"
                                    error={errors.account_number}
                                    className={classnames("form-control", {
                                      invalid: errors.account_number,
                                    })}
                                  />
                                  <span className="text-danger">
                                    {errors.account_number}
                                  </span>
                                </div>
                                <div className="col-md-4 col-12">
                                  <label htmlFor="street">Routing Number</label>
                                  <input
                                    onChange={this.onChange}
                                    value={this.state.routing_number}
                                    id="routing_number"
                                    type="text"
                                    error={errors.routing_number}
                                    className={classnames("form-control", {
                                      invalid: errors.routing_number,
                                    })}
                                  />
                                  <span className="text-danger">
                                    {errors.routing_number}
                                  </span>
                                </div>
                                <br></br>
                                <br></br>
                              </div>
                            )}
                          </div>
                          <br></br>
                          <br></br>
                          <br></br>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "15px" }}
                          >
                            <h5 htmlFor="name">Billing Address</h5>
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="name">Name</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.name}
                              id="name"
                              type="text"
                              error={errors.name}
                              className={classnames("form-control", {
                                invalid: errors.name,
                              })}
                            />
                            <span className="text-danger">{errors.name}</span>
                          </div>
                          <div className="col-md-6 col-12">
                            <label htmlFor="street">Address Line 1</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.street}
                              id="street"
                              type="text"
                              error={errors.street}
                              className={classnames("form-control", {
                                invalid: errors.street,
                              })}
                            />
                            <span className="text-danger">{errors.street}</span>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmlFor="city">City</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.city}
                              id="city"
                              type="text"
                              error={errors.city}
                              className={classnames("form-control", {
                                invalid: errors.city,
                              })}
                            />
                            <span className="text-danger">{errors.city}</span>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmlFor="state">State</label>
                            <select
                              name="state"
                              onChange={this.onChange}
                              className="form-control"
                              id="state"
                            >
                              <option value="" selected="">
                                Please Select
                              </option>
                              {Object.keys(usState).map((each, i) => (
                                <option
                                  key={i}
                                  value={each}
                                  selected={this.state.billing_state === each}
                                >
                                  {usState[each]}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-6 col-12">
                            <label htmsuperadminlFor="zip">Zip</label>
                            <input
                              onChange={this.onChange}
                              value={this.state.zip}
                              id="zip"
                              type="text"
                              error={errors.zip}
                              className={classnames("form-control", {
                                invalid: errors.zip,
                              })}
                            />
                            <span className="text-danger">{errors.zip}</span>
                          </div>
                        </div>
                        <p className="text-center pb-0 mt-2">
                          <button
                            type="button"
                            className="btn btn-large btn-primary mt-2 px-5 float-left"
                            onClick={() => this.setState({ current: "first" })}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-large btn-primary mt-2 px-5 float-right"
                            onClick={this.register}
                          >
                            Submit Request
                          </button>
                        </p>
                        <br></br>
                      </div>
                      <h5>
                        <b>
                          <i>
                            There will be 3% convenience fee for all credit card
                            orders
                          </i>
                        </b>
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
